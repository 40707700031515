<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header> Filtros de búsqueda</v-expansion-panel-header>
      <v-expansion-panel-content>
        <CRow>
          <CCol col="5" xl="5">
            <v-select
              v-model="campo"
              :items="campos"
              :item-text="'label'"
              :item-value="'value'"
              attach
              label="Campo"
              return-object
            ></v-select>
          </CCol>
          <CCol col="5" xl="5">
            <v-text-field
              v-if="campo && !campo.options"
              v-model="buscar"
              label="Texto a buscar..."
              :rules="rules"
              hide-details="auto"
            ></v-text-field>

            <v-select
              v-if="campo.options && campo.options.length"
              v-model="buscar"
              :items="campo.options"
              :item-text="itemsKeys.text"
              :item-value="itemsKeys.value"
              attach
              label="Buscar por..."
            ></v-select>
          </CCol>
          <CCol col="2" xl="2">
            <CButton
              color="primary"
              class="px-4"
              :disabled="!(campo && buscar!=='')"
              @click="buscarEmitEvent()"
              ><CIcon name="cil-magnifying-glass" /> Buscar</CButton
            >
          </CCol>
        </CRow>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import EventBus from "@/common/EventBus"

export default {
  name: "Filters",
  props: {
    campos: {
      type: Array,
      default: () => [],
    },
    itemsKeys: {
      type: Object,
      default: {
        text: "name",
        value: "id",
      },
    },
    eventName: {
      type: String,
      default: "fetchItemsEvent",
    },
  },
  data() {
    return {
      campo: "",
      buscar: "",
      rules: [
        (value) => !!value || "Requerido",
        //(value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
    };
  },
  watch: {
    campo: function (val) {
      this.buscar = '';
    }
  },
  mounted() {},
  methods: {
    buscarEmitEvent() {
      let cadenaBusqueda = `?${this.campo.value}=${this.buscar}`;
      EventBus.dispatch(this.eventName, cadenaBusqueda)
    },
  },
};
</script>

<style>
</style>
